<template>
  <section>
    <SetPassword />
  </section>
</template>
<script>
import SetPassword from '@/components/SetPassword.vue';
export default {
  components: {
    SetPassword
  }
};
</script>

<style lang="sass" scoped></style>
